<template>
  <section ref="deviceAlerts" class="device-alerts-container">
    <transition name="fade">
      <div class="custom-button-container">
        <span>
          <TableSearch :tableTitles="tableTitles" @input="handleInput" />
        </span>
        <button class="export-button-container" @click="openFullScreen">
          <img :src="require('@/assets/full_screen.svg')" alt="full screen" />
        </button>
      </div>
    </transition>
    <Tabs :isRTL="isRTL" :syncRoute="true">
      <Tab :label="$t('device-alerts')" to="/admin/device-alerts" :exact="true" class="device-alerts">
        <div class="table-header">
          <TableCell
            v-for="fieldName in fieldNames"
            :isActive="fieldName === 'pad' && !!siteDevices"
            :key="fieldName"
            :item="fieldName"
            :isHeader="true"
            @cellClick="handleSortClick"
          />
        </div>
        <template v-if="activeTab === 'device-alerts'">
          <Loading v-if="!siteDevices" class="loader" style="height:75vh;" />
          <div
            v-else
            v-for="(site, idx) in siteDevicesToShow"
            :key="idx + 1 * Math.random()"
            @click="routeToSiteDevices(site)"
            class="table-row"
            :class="{ pinned: site.isPinned }"
          >
            <p>
              <Checkbox @click.native.stop :value="site.isPinned" @input="handlePin(site, $event)"></Checkbox>
            </p>
            <p>{{ idx + 1 }}.</p>
            <p class="table-cell text-cell">
              <span>{{ site.company.name }}</span>
            </p>
            <p class="table-cell text-cell">
              <span>{{ site.site }}</span>
            </p>
            <p class="room-name">{{ site.room }}</p>
            <span
              v-for="([, device], idx) in Object.entries(site).filter(
                ([key, value]) => key !== 'sensor' && key !== 'qr_tag' && key !== 'qr_task' && typeof value === 'object' && !value.name
              )"
              :key="idx"
              class="device-container"
            >
              <DeviceStatus v-if="device.total" :status="device.err" isCount isError />
            </span>
            <p>
              {{ site.first_date !== 'N/A' ? new Date(site.first_date).toLocaleDateString('en-GB') : site.first_date }}
            </p>
            <p>{{ site.site_country }}</p>
            <p class="table-cell text-cell">
              <span>{{ site.site_state }}</span>
            </p>
            <p class="table-cell text-cell">
              <span>{{ site.site_city }}</span>
            </p>
          </div>
          <div class="table-row sum">
            <p>{{ $t('total_sites') }}:</p>
            <template v-for="idx in 3">
              <p :key="idx * Math.random()"></p>
            </template>
            <p class="room-name">{{ sitesTotal('room') }}</p>
            <span
              v-for="(type, idx) in ['pad', 'pad_counter', 'tablet', 'counter']"
              :key="idx"
              class="device-container"
            >
              <DeviceStatus :status="sitesTotal(type)" isCount isError />
            </span>
            <template v-for="idx in 4">
              <p :key="idx * Math.random()"></p>
            </template>
          </div>
        </template>
      </Tab>
      <Tab :label="$t('device-active')" to="/admin/device-alerts/active" class="device-active">
        <div class="table-header">
          <TableCell
            v-for="fieldName in fieldNames"
            :key="fieldName"
            :item="fieldName"
            :isHeader="true"
            @cellClick="handleSortClick"
          />
        </div>
        <template v-if="activeTab === 'device-active'">
          <Loading v-if="!siteDevices" class="loader" style="height:75vh;" />
          <div v-else v-for="(site, idx) in siteDevicesToShow" :key="idx + 1 * Math.random()" class="table-row">
            <p class="table-cell text-cell">{{ site.company.name }}</p>
            <p class="table-cell text-cell">{{ site.site }}</p>
            <p class="room-name">{{ site.room }}</p>
            <span
              v-for="([, device], idx) in Object.entries(site).filter(
                ([key, value]) => key !== 'pad_counter' && typeof value === 'object' && !value.name
                && key !== 'qr_task'
              )"
              :key="idx"
              class="device-container"
            >
              <DeviceStatus :status="device.total" isCount />
            </span>
            <p class="table-cell text-cell">{{ site.franchise_name }}</p>
          </div>
          <div class="table-row sum">
            <p>{{ $t('total_sites') }}:</p>
            <p></p>
            <p class="room-name">{{ sitesTotal('room') }}</p>
            <span
              v-for="(type, idx) in ['pad', 'tablet', 'counter', 'qr_tag', 'sensor']"
              :key="idx"
              class="device-container"
            >
              <DeviceStatus :status="sitesTotal(type)" isCount />
            </span>
            <template v-for="idx in 4">
              <p :key="idx * Math.random()"></p>
            </template>
          </div>
        </template>
      </Tab>
      <Tab :label="$t('device-counter')" to="/admin/device-alerts/counter" class="device-counter">
        <div class="table-header">
          <TableCell
            v-for="fieldName in fieldNames"
            :key="fieldName"
            :item="fieldName"
            :isHeader="true"
            :isIgnored="fieldName === 'level' ? true : false"
            @cellClick="handleSortClick"
          />
        </div>
        <template v-if="activeTab === 'device-counter'">
          <Loading v-if="!faultyCounters" class="loader" style="height:75vh;" />
          <div v-else v-for="(counter, idx) in faultyCountersToShow" :key="idx + 1 * Math.random()" class="table-row">
            <p class="table-cell text-cell">{{ counter.company_name }}</p>
            <p class="table-cell text-cell">{{ counter.site_name }}</p>
            <p class="room-name">{{ counter.room_name }}</p>
            <p class="table-cell text-cell">{{ counter.type }}</p>
            <p class="table-cell text-cell">{{ counter.version }}</p>
            <p class="table-cell text-cell">{{ counter.device_code }}</p>
            <p class="table-cell text-cell">{{ counter.counts }}</p>
            <p class="table-cell text-cell">{{ faultyCounterLevels(counter.levels) }}</p>
            <p class="table-cell text-cell">{{ new Date(counter.date).toLocaleDateString('en-gb') }}</p>
            <p class="table-cell text-cell">{{ new Date(counter.lastDate).toLocaleDateString('en-gb') }}</p>
            <img
              :src="require('@/assets/delete-icon.svg')"
              alt="remove-icon"
              class="cell-icon action"
              @click="removeFaultyCounter(counter.device_code)"
            />
          </div>
        </template>
      </Tab>
      <Tab :label="$t('device-search')" to="/admin/device-alerts/search" class="device-search">
        <div class="table-header">
          <TableCell v-for="fieldName in fieldNames" :key="fieldName" :item="fieldName" :isHeader="true" />
        </div>
        <template v-if="activeTab === 'device-search'">
          <Loading v-if="isSearching" class="loader" style="height:75vh;" />
          <div
            v-if="device"
            @click="routeToSiteDevices({ company: { id: device.company_id }, site_id: device.site_id })"
            class="table-row"
          >
            <p class="table-cell text-cell">{{ device.company_name }}</p>
            <p class="table-cell text-cell">{{ device.site_name }}</p>
            <p class="room-name">{{ device.room_name }}</p>
            <p>{{ device.type }}</p>
            <p class="table-cell text-cell">{{ device.site_country }}</p>
            <p class="table-cell text-cell">{{ device.site_state }}</p>
            <p class="table-cell text-cell">{{ device.site_city }}</p>
            <p class="table-cell text-cell">{{ device.franchise_name }}</p>
          </div>
        </template>
      </Tab>
    </Tabs>
  </section>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import DeviceStatus from '@/modules/devices/components/DeviceStatus'
import Loading from '@/modules/common/components/Loading'
import Tab from '@/modules/common/components/Tabs/Tab'
import Tabs from '@/modules/common/components/Tabs/Tabs'
import TableCell from '@/modules/common/components/customTable/TableCell'
import TableSearch from '@/modules/common/components/customTable/TableSearch'
import Checkbox from '@/modules/common/components/Checkbox'
import sensorService from '../services/sensorService'
import swalService from '../../common/services/swalService'

export default {
  name: 'DeviceAlerts',
  data() {
    return {
      siteDevices: null,
      faultyCounters: null,
      siteDevicesCopy: null,
      faultyCountersCopy: null,
      sortActive: false,
      searchTxt: '',
      searchBy: '',
      device: null,
      isSearching: false,
    }
  },
  async created() {
    this.faultyCounters = await sensorService.getFaultyCounters()
    this.faultyCountersCopy = [...this.faultyCounters]

    this.siteDevices = await sensorService.getSitesSensorsStatus()

    if (localStorage['pinnedSites'])
      JSON.parse(localStorage['pinnedSites']).forEach((id) => {
        const idx = this.siteDevices.findIndex(({ site_id }) => site_id === id)
        if (idx === -1) return
        this.siteDevices[idx].isPinned = true
      })

    this.siteDevicesCopy = [...this.siteDevices]
  },
  methods: {
    ...mapMutations(['updateFilter']),
    routeToSiteDevices(site) {
      this.updateFilter({ field: 'company', value: site.company.id })
      this.updateFilter({ field: 'site', value: site.site_id })
      this.$router.push({ name: 'devices' })
    },
    openFullScreen() {
      const deviceAlertsEl = this.$refs.deviceAlerts
      //browser compatibility
      if (deviceAlertsEl.msRequestFullscreen) deviceAlertsEl.msRequestFullscreen()
      else if (deviceAlertsEl.mozRequestFullScreen) deviceAlertsEl.mozRequestFullScreen()
      else if (deviceAlertsEl.webkitRequestFullscreen) deviceAlertsEl.webkitRequestFullscreen()
      else deviceAlertsEl.requestFullscreen()
    },
    handlePin(site, isPinned) {
      site.isPinned = isPinned
      let savedPinnedSitesIDs = JSON.parse(localStorage['pinnedSites'] || null)
      if (!savedPinnedSitesIDs) savedPinnedSitesIDs = []
      if (site.isPinned) savedPinnedSitesIDs.push(site.site_id)
      else savedPinnedSitesIDs = savedPinnedSitesIDs.filter((savedSiteID) => site.site_id !== savedSiteID)
      localStorage['pinnedSites'] = JSON.stringify(savedPinnedSitesIDs)
    },
    handleSortClick(fieldName, isActive) {
      fieldName = fieldName
        .split(' ')
        .join('_')
        .toLowerCase()

      if (this.activeTab === 'device-counter') {
        if (fieldName === 'mac_id') fieldName = 'device_code'
        if (fieldName === 'count') fieldName = 'counts'
        if (fieldName === 'level') fieldName = 'levels'
        if (fieldName === 'first_date') fieldName = 'date'
        if (fieldName === 'last_date') fieldName = 'lastDate'
      }

      const sourceArray = this.activeTab === 'device-counter' ? 'faultyCounters' : 'siteDevices'
      const sourceArrayCopy = this.activeTab === 'device-counter' ? 'faultyCountersCopy' : 'siteDevicesCopy'

      if (isActive) {
        this[sourceArray].sort((a, b) => {
          if (
            (a[fieldName] && b[fieldName]) ||
            (a[fieldName] === 0 && b[fieldName]) ||
            (a[fieldName] && b[fieldName] === 0) ||
            (a[fieldName] === 0 && b[fieldName] === 0)
          ) {
            if (typeof a[fieldName] === 'number') return b[fieldName] - a[fieldName]

            if (typeof a[fieldName] === 'string') {
              if (a[fieldName] > b[fieldName]) return 1
              return -1
            }

            if (typeof a[fieldName] === 'object' && a[fieldName].name) {
              if (a[fieldName].name - b[fieldName].name) return 1
              return -1
            }

            if (this.activeTab === 'device-alerts' && typeof a[fieldName] === 'object' && a[fieldName].err) {
              if (a[fieldName].err > b[fieldName].err) return -1
              return 1
            }

            if (this.activeTab === 'device-active' && typeof a[fieldName] === 'object') {
              if (a[fieldName].total > b[fieldName].total) return -1
              return 1
            }
          }
        })
      } else {
        this[sourceArray] = [...this[sourceArrayCopy]]
      }
    },
    async handleInput(value, searchBy) {
      const { name } = this.$route

      if (name === 'device-search') {
        if (!value) return

        this.isSearching = true

        if (this.debouncedFetch) clearTimeout(this.debouncedFetch)

        this.debouncedFetch = setTimeout(async () => {
          this.device = (await sensorService.getByDeviceCode(value)) || null
          this.isSearching = false
        }, 1000)
        return
      }

      if (searchBy) this.searchBy = searchBy
      this.searchTxt = value
    },
    faultyCounterLevels(levels) {
      if (!levels[4]) return '3'
      else if (!levels[3]) return '4'
      return '3 & 4'
    },
    async removeFaultyCounter(deviceCode) {
      const result = await swalService.confirmMsg('Are you sure you want to delete this faulty counter?')

      if (!result.value) return

      try {
        await sensorService.removeFaultyCounter(deviceCode)
        swalService.deletedMsg('Faulty counter has been deleted successfully.')
        this.faultyCounters = await sensorService.getFaultyCounters()
        this.faultyCountersCopy = [...this.faultyCounters]
      } catch (error) {
        console.log('Could not delete the faulty counter.')
      }
    },
  },
  computed: {
    ...mapGetters(['filterSelected', 'isRTL']),
    sitesTotal() {
      return (field) =>
        this.siteDevicesToShow.reduce(
          (sum, device) =>
            field === 'room'
              ? sum + device[field]
              : this.activeTab === 'device-alerts'
              ? sum + device[field].err
              : sum + device[field].total,
          0
        )
    },
    siteDevicesToShow() {
      const pinnedSites = []
      let filteredSites
      if (!this.siteDevices) return []
      if (!this.searchTxt) {
        filteredSites = this.siteDevices.filter((device) => {
          if (this.activeTab === 'device-alerts' && device.isPinned) {
            pinnedSites.push(device)
            return false
          }
          return this.activeTab === 'device-alerts'
            ? device.counter.err || device.pad.err || device.pad_counter.err || device.tablet.err
            : true
        })
        return this.activeTab === 'device-alerts' ? pinnedSites.concat(filteredSites) : filteredSites
      }
      filteredSites = this.siteDevices.filter((device) => {
        if (this.activeTab === 'device-alerts' && device.isPinned) {
          pinnedSites.push(device)
          return false
        }
        if (
          this.activeTab === 'device-active' ||
          ((this.activeTab === 'device-alerts' && device.counter.err) ||
            device.pad.err ||
            device.pad_counter.err ||
            device.tablet.err)
        ) {
          const { searchTxt, searchBy } = this
          if (searchBy === 'company') {
            return device[searchBy].name.toLowerCase().includes(searchTxt.toLowerCase())
          } else {
            if (typeof device[searchBy] === 'number') {
              return device[searchBy].toString() === searchTxt
            } else {
              return device[searchBy].toLowerCase().includes(searchTxt.toLowerCase())
            }
          }
        }
      })
      return this.activeTab === 'device-alerts' ? pinnedSites.concat(filteredSites) : filteredSites
    },
    faultyCountersToShow() {
      if (!this.faultyCounters) return []
      if (!this.searchTxt) return this.faultyCounters
      return this.faultyCounters.filter((counter) => {
        if (this.activeTab === 'device-counter') {
          let { searchTxt, searchBy } = this

          if (searchBy === 'first_date') searchBy = 'date'
          else if (searchBy === 'last_date') searchBy = 'lastDate'

          if (typeof counter[searchBy] === 'number') return counter[searchBy].toString() === searchTxt
          else {
            if (new Date(counter[searchBy]).toString() !== 'Invalid Date')
              new Date(counter[searchBy]).toLocaleDateString('en-gb').includes(searchTxt)
            return counter[searchBy].toLowerCase().includes(searchTxt.toLowerCase())
          }
        }
      })
    },
    fieldNames() {
      const fields = {
        'device-alerts': [
          'pinned',
          'no.',
          'company',
          'site',
          'room',
          'pad',
          'pad_counter',
          'tablet',
          'counter',
          'first_date',
          'site_country',
          'site_state',
          'site_city',
        ],
        'device-active': ['company', 'site', 'room', 'pad', 'tablet', 'counter', 'qr_tag', 'sensor', 'franchise'],
        'device-counter': [
          'company_name',
          'site_name',
          'room_name',
          'type',
          'version',
          'mac_id',
          'count',
          'level',
          'first_date',
          'last_date',
        ],
        'device-search': ['company', 'site', 'room', 'type', 'site_country', 'site_state', 'site_city', 'franchise'],
      }

      return fields[this.activeTab]
    },
    tableTitles() {
      const titles = {
        'device-alerts': ['company', 'site', 'room', 'site_state', 'site_city'],
        'device-active': ['company', 'site', 'franchise_name'],
        'device-counter': ['company_name', 'site_name', 'first_date', 'last_date'],
        'device-search': ['mac_id'],
      }
      return titles[this.activeTab]
    },
    activeTab() {
      return this.$route.name
    },
  },
  watch: {
    '$route.name': {
      handler: function(value, oldValue) {
        this.$children[0].searchTxt = ''
      },
      deep: true,
    },
  },
  components: {
    DeviceStatus,
    Loading,
    Tab,
    Tabs,
    TableCell,
    TableSearch,
    Checkbox,
  },
}
</script>

<style lang="scss">
.device-alerts-container .table-row {
  .b-input {
    position: static !important;

    &::after {
      left: 0.5rem !important;
      top: 0.25rem !important;
    }
  }
}
</style>

<style lang="scss" scoped>
@import '@/styles/vars.scss';
@import '@/styles/mixins.scss';

.pinned {
  background-color: #efeded;
}

.table-cell {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;

  &.text-cell {
    overflow: hidden;

    > span {
      text-overflow: ellipsis;
      overflow: hidden;
      padding-bottom: 10px;
      margin-bottom: -10px;
      white-space: nowrap;

      &:hover {
        overflow: visible;
        white-space: normal;
      }
    }
  }
}

.device-alerts-container {
  min-height: 85vh;
  position: relative;

  * {
    font-size: $font-size-regular;
  }

  .table-row:hover {
    .cell-icon {
      visibility: visible;
    }
  }

  .cell-icon {
    visibility: hidden;

    &:hover {
      cursor: pointer;
    }
  }

  .table-header {
    height: 6vh;
    width: 100%;
    border-bottom: 4px solid #e7e7e7;
    display: grid;

    & > * {
      display: flex;
      align-items: center;
      padding-inline-start: 35px;
      // text-transform: capitalize;
    }

    p {
      font-weight: $font-weight-regular;
      color: #909090;
    }

    .export-button-container {
      display: flex;
      align-items: center;
      margin-inline-start: 15px;
    }
  }

  .table-row {
    cursor: pointer;
    position: relative;
    height: 5vh;
    width: 100%;
    display: grid;
    border-bottom: 1px solid #e7e7e7;

    .room-name {
      font-weight: $font-weight-bold;
    }

    &.sum {
      cursor: default;
    }

    & > * {
      height: 100%;
      display: flex;
      align-items: center;
      padding-inline-start: 35px;
    }

    & > img {
      align-self: center;
      height: unset;
      padding: unset;
      padding-inline-start: 15px;
    }

    &:not(.sum):hover {
      background-color: #f5fafe;
    }

    .device-container {
      display: grid;
      grid-template-columns: 0.8fr repeat(3, 1fr);
    }
  }
}

.table-header,
.table-row {
  .device-alerts & {
    grid-template-columns: 90px 60px 200px 200px repeat(5, 1fr) 100px 120px 150px 130px;
  }
  .device-active & {
    grid-template-columns: 300px 300px repeat(6, 7fr) 250px;
  }
  .device-counter & {
    grid-template-columns: 2fr 2fr 2fr 1fr 0.8fr 2fr 0.8fr 0.8fr 1.2fr 1.2fr 50px;
  }
  .device-search & {
    grid-template-columns: 250px 250px 250px repeat(5, 7fr);
  }
}

.custom-button-container {
  height: 10vh;
  margin: 0 auto;
  display: flex;
  align-items: center;
  position: absolute;
  right: 50px;
  z-index: 3;
  font-weight: $font-weight-regular;

  @include rtl {
    left: 0;
    right: unset;
  }

  & > * {
    margin: 0 10px;
  }

  span {
    @include modal-input-label;
    margin-bottom: 0px;
    font-size: 16px;
    text-transform: uppercase;
  }

  input {
    @include modal-input;
    margin-inline-start: 5px;
    margin-bottom: 0px;
    padding: 0 5px;
    width: 200px;
  }
}
</style>
